import { graphql, useStaticQuery } from "gatsby";
import React, { useEffect, useState } from "react";
import Slider from "react-slick";

import "./review-carousel.scss"

const quotesQuery = graphql`
  {
    hyco {
      reviews {
        imageUrl
        quote {
          html
        }
      }
    }
  }
`;

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
  return array;
}

const ReviewCarousel = () => {
  const [showVideo, setShowVideo] = useState(false);
  let [newReviews, setReviews] = useState(null);

  const settings = {
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
  };

  const {
    hyco: { reviews }
  } = useStaticQuery(quotesQuery);

  const onScroll = () => {
    if (showVideo === false) {
      setShowVideo(true);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onMouseMove);
    }
  }

  const onMouseMove = () => {
    if (showVideo === false) {
      setShowVideo(true);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('touchmove', onMouseMove);
    }
  }

  if (typeof window !== "undefined") {
    window.addEventListener('scroll', onScroll, true);
    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('touchmove', onMouseMove);
  }

  useEffect(() => {
    setReviews(shuffleArray(reviews).map((review, index) => {
      return (
        <span className="row" key={index}>
          <img className="col-md-3" src={review.imageUrl} alt="client-logo" loading="lazy" />
          <pre className="col-md-9"><div className="html-text inline" dangerouslySetInnerHTML={{ __html: review.quote.html }} /></pre>
        </span>
      );
    }));
  }, [reviews]);

  return (
    <>
      <div className="review-carousel-container container">
        <div className="row">
          <h1>Zij gingen je al voor:</h1>
        </div>
        {showVideo === true && <div className="carousel">
          <Slider {...settings}>
            {newReviews}
          </Slider>
        </div>}
      </div>
    </>
  );
}

export default ReviewCarousel
