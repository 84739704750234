import React from "react";
import SEO from "../components/seo"
import Layout from "../components/layout"
import CompleetJourney from "../components/compleetJourney"

import "./start-pakket.scss";

const CompleetPage = (queryData) => {
    return (
        <Layout>
            <SEO title="Start pakket voor kleine reisondernemers" urlParams="start-pakket" page="start-pakket" crawlable="true" />
            <img className="print-image" src='https://media.travelspirit.nl/original/sm/TravelSpirit_Logo-FC.png' alt="logo" loading="lazy" />
            <CompleetJourney />
        </Layout>
    )
}

export default CompleetPage
